<template>
<div>
    <v-col cols="12" sm="4">
        <h4>Listado de Patologias</h4>
    </v-col>
    <v-row align="center" class="list px-3 mx-auto">
        <v-col cols="12" sm="8">
            <v-text-field v-model="searchPatologia" label="Buscar por Nombre"></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
            <v-btn @click="page = 1; retrievePatologias();">
                Search
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
            <v-row>
                <v-col cols="4" sm="3">
                    <v-select v-model=" pageSize " :items=" pageSizes " label="Patologias por Pagina" @change=" handlePageSizeChange "></v-select>
                </v-col>
                <v-col cols="12" sm="9">
                    <v-pagination v-model=" page " :length=" totalPages " total-visible="7" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input=" handlePageChange "></v-pagination>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile>
                <v-card-title>Patologias</v-card-title>
                <v-data-table :headers=" headers " :items=" patologias " disable-pagination :hide-default-footer=" true " :custom-sort=" customSort ">
                    <template v-slot:[`item.actions`]=" { item } ">
                        <v-btn icon color="blue" class="mr-2" @click=" editarPatologia(item.id) ">
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn color="red lighten-2" dark icon @click=" (deleteConfirmation(item.id)) ">
                             <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
import PatologiaDataService from "../services/PatologiaDataService";
import ConfirmDialogue from '../components/ConfirmDialogue.vue';

export default {

    name: "patologias-list",
    components: {
        ConfirmDialogue
    },
    data() {
        return {
            dialog: false,
            searchPatologia: "",
            patologias: [],
            headers: [{
                    text: "Nombre",
                    align: "start",
                    sortable: true,
                    value: "nombre"
                },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false
                },
            ],

            page: 1,
            totalPages: 0,
            pageSize: 12,
            pageSizes: [12, 24, 48],
        };
    },
    methods: {

        async deleteConfirmation(idToDelete) {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Desea eliminar la Patologia?',
                message: 'Esta accion no se puede deshacer.',
                okButton: 'Eliminar',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.deletePatologia(idToDelete)
            } else {
                //    alert('You chose not to delete this page. Doing nothing now.')
            }
        },

        deletePatologia(id) {
            PatologiaDataService.delete(id)
                .then(() => {
                    this.refreshList();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        //customSort: the native sort function of the data view table doesn't works as it should, this custom implementation solves it
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == 'date') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                } else {
                    if (typeof a[index] !== 'undefined') {
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        } else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
                console.log(items);
            });
            return items;

        },
        getRequestParams(searchPatologia, page, pageSize) {
            let params = {};
            if (searchPatologia) {
                params["nombre"] = searchPatologia;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["size"] = pageSize;
            }
            return params;
        },
        retrievePatologias() {
            const params = this.getRequestParams(
                this.searchPatologia,
                this.page,
                this.pageSize
            );
            PatologiaDataService.getAll(params)
                .then((response) => {
                    const {
                        patologias,
                        totalPages
                    } = response.data;
                    this.patologias = patologias.map(this.getDisplayPatologia);
                    this.totalPages = totalPages;
                    console.log(response.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        handlePageChange(value) {
            this.page = value;
            this.retrievePatologias();
        },
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.page = 1;
            this.retrievePatologias();
        },
        getDisplayPatologia(patologia) {
            return {
                id: patologia.id,
                nombre: patologia.nombre.substr(0, 40),
                fechaupdate: patologia.updatedAt.substring(0, 10),
            };
            
        },
        refreshList() {
            this.retrievePatologias();
        },
        editarPatologia(id) {
            this.$router.push({
                name: "patologias-edit",
                params: {
                    id: id
                }
                
            });
            console.log(id)
        },
    },
    mounted() {
        this.retrievePatologias();
    },
};
</script>
